import React, { useEffect } from "react";

const RedirectPage = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/android/i.test(userAgent)) {
      window.location.href = "https://example.com/link1"; // Replace with your Android link
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://example.com/link2"; // Replace with your Apple link
    } else {
      alert("Unsupported device");
    }
  }, []);

  return <p>Redirecting...</p>;
};

export default RedirectPage;
